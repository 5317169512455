<style scoped></style>

<template>
  <Form label-position="top" ref="form" :model="formData" :rules="rules">
    <FormItem label="数量" prop="volume">
      <InputNumber controls-outside v-model="formData.volume" />
    </FormItem>
    <FormItem label="库存" prop="goodsStockId">
      <Select clearable v-model="formData.goodsStockId" placeholder="请选择库存">
        <Option v-for="item in stockList" :key="item.id" :value="item.id">
          {{ item.storehouseName }} / {{ item.storehouseUnitName }} /
          {{ item.volume }} {{ item.goodsUnit }}
        </Option>
      </Select>
    </FormItem>
  </Form>
</template>

<script>
import request from '../../../../api'

export function getDefault () {
  return {
    goodsStockId: null,
    volume: null,
  }
}

export default {
  props: {
    data: { type: Object, default: null },
    storehouseId: { type: Number, default: null }
  },
  data () {
    return {
      formData: getDefault(),
      stockList: [],
      loading: {
        search: false
      }
    }
  },
  computed: {
    rules () {
      return {
        volume: [
          { required: true, message: '请输入数量' }
        ],
        goodsStockId: [
          { required: true, message: '请选择库存' }
        ]
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.loadStockList()
      }
    }
  },
  methods: {
    async loadStockList () {
      if (!this.data) {
        this.stockList = []
      } else {
        this.stockList = await request.get('/goods_stock', {
          goodsId: this.data.goodsId,
          storehouseId: this.storehouseId
        })
      }
    },
    getFormData () {
      return new Promise(resolve => {
        this.$refs.form.validate((valid) => {
          if (valid) {
            resolve(JSON.parse(JSON.stringify(this.formData)))
          } else {
            resolve(false)
          }
        })
      })
    }
  },
  mounted () {
    this.loadStockList()
  }
}
</script>
